import React from "react";
import InputBox from "../../../common/input/InputBox";

const StockInComponent = ({ stockInData, formik, stockInSerial }) => {
  return (
    <div>
      <form>
        <div className="shadow-card rounded-15 pb-4 mb-8">
          <div
            className={`shadow-card rounded-tl-15 rounded-tr-15 bg-secondary p-3 flex flex-wrap gap-2 justify-between text-bodyBB items-center mb-4  h-[50px]`}
          >
            <div>Item Details</div>
            <div>{stockInSerial}</div>
          </div>
          <div className="grid  lg:grid-cols-3 gap-5 w-full mt-2  p-5">
            <div className="grid grid-flow-row ">
              <InputBox
                type="text"
                name={"catagory"}
                title={"Category"}
                placeholder="Category"
                // onChange={formik?.handleChange}
                value={formik?.values?.catagory}
                error={formik?.errors.catagory && formik?.touched.catagory}
                className="h-[50px]"
              />
            </div>
            <div className="grid grid-flow-row ">
              <InputBox
                type="text"
                name={"sub_category"}
                title={"Sub Category"}
                placeholder="Sub Category"
                // onChange={formik?.handleChange}
                value={formik?.values?.sub_category}
                error={
                  formik?.errors.sub_category && formik?.touched.sub_category
                }
                className="h-[50px]"
              />
            </div>
            <div className="grid grid-flow-row ">
              <InputBox
                type="text"
                name={"code"}
                title={"Item Code"}
                placeholder="Item Code"
                // onChange={formik.handleChange}
                value={formik?.values?.code}
                error={formik?.errors.code && formik?.touched.code}
                className="h-[50px]"
              />
            </div>
            <div className="grid grid-flow-row ">
              <InputBox
                type="text"
                name={"name"}
                title={"Item Name"}
                placeholder="Item Name"
                // onChange={formik?.handleChange}
                value={formik?.values?.name}
                error={formik?.errors.name && formik?.touched.name}
                className="h-[50px]"
              />
            </div>
            <div className="grid grid-flow-row ">
              <InputBox
                title={"Generic Name"}
                placeholder="Generic Name"
                className=" h-[50px] text-darkgrey text-bodyRB"
                value={formik.values.generic_name}
                error={
                  formik.touched.generic_name && formik.errors.generic_name
                }
                name={"generic_name"}
                onChange={formik.handleChange}
              />
            </div>

            <div className="grid grid-flow-row ">
              <InputBox
                type="text"
                name={"brand"}
                title={"Brand Name"}
                placeholder="Brand Name"
                className="h-[50px]"
                // onChange={formik?.handleChange}
                value={formik?.values.brand}
                error={formik?.errors.brand && formik?.touched.brand}
              />
            </div>
            {/* <div className="grid grid-flow-row ">
            <InputBox
              type="text"
              name={"modal"}
              title={"Model Name"}
              placeholder="Model Name"
              // onChange={formik?.handleChange}
              value={formik?.values.modal}
              error={formik?.errors.modal && formik?.touched.modal}
            />
          </div> */}

            <div className="grid grid-flow-row ">
              <InputBox
                type="number"
                name={"selling_price"}
                title={"Selling Price Per Unit"}
                placeholder="Selling Price Per Unit"
                className="h-[50px]"
                // onChange={formik?.handleChange}
                value={formik?.values.selling_price}
                error={
                  formik?.errors.selling_price && formik?.touched.selling_price
                }
              />
            </div>
            <div className="grid grid-flow-row ">
              <InputBox
                type="text"
                name={"quantity"}
                title={"Available Quantity"}
                placeholder={
                  formik?.values.quantity === 0 ? "0" : "Available Quantity"
                }
                className="h-[50px] bg-success bg-opacity-30 !text-black "
                // onChange={formik?.handleChange}
                value={formik?.values.quantity}
                error={formik?.errors.quantity && formik?.touched.quantity}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StockInComponent;
