import axios from "axios";
import { generateAuthenticationDetails } from "../../utils/generateAuthInfo";

export const baseURL = "https://api.simpld.in/";

export const api = axios.create({
  // baseURL: "http://210.16.89.226:3005/api/",
  baseURL: baseURL + "api/",
  // baseURL: "http://localhost:3005/api/",
});

// Request interceptor
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  const { timestamp, securityCode } = generateAuthenticationDetails();
  // |${timestamp},${securityCode}
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}|${timestamp},${securityCode}`,
    };
  }
  return config;
});

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Successful response
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 && !error.config._retry) {
      // Handle 401 Unauthorized
      return Promise.reject(error);
    } else if (error.response?.status === 401) {
      // Handle 401 Unauthorized without refresh token
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
