import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanupUserSettings,
  closeProfileModal,
  openProfileModal,
} from "../../../store/slice/auth-slice";
import { getLocationName } from "../../../utils/windowname";
import { getAllChildren } from "../../../utils/routes_childs";
import { useEffect, useRef, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { RiFullscreenFill } from "react-icons/ri";
import ProfileModal from "../../../pages/Profile";
import Hamburger from "../../icons/Hamburger";
import ProfileIcon_mob from "../../icons/ProfileIcon_mob";
import LogoutIcon from "../../icons/Logout";
import PopupImages from "../popup";
import routes from "../../../routes";
import { BiSolidBell } from "react-icons/bi";
import { FaUserLarge } from "react-icons/fa6";
import {
  clearAllUnReadNotification,
  getUserNotifications,
  updateUnReadNotification,
} from "../../../api/auth/user";
import dayjs from "dayjs";
import Close from "../../icons/Close";
import { baseURL } from "../../../config/axios/interceptor";

const Header = ({ handleMobSideBar }) => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const formattedPathname = getLocationName(location);
  const additionalBackButtonPaths = getAllChildren();
  const [showLogout, setShowLogout] = useState(false);
  const logoutButtonRef = useRef(null);
  const currentUser = useSelector((state) => state.auth);

  const [notification, setNotification] = useState(null);
  const notificationOpen = Boolean(notification);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListLoading, setNotificationListLoading] = useState(false);
  const notificationIconRef = useRef(); // Specify the type
  const notificationListRef = useRef(); // Specify the type

  /** Notification pagination on scroll */
  const stackRef = useRef(null);
  const [total_page, setTotal_page] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // Use this state to track the current page
  const [loading, setLoading] = useState(false);

  /**  */
  const [hasUnRead, setHasUnRead] = useState(false);

  const handleBodyClick = (event) => {
    // Check if the clicked element is inside the box or the icon
    if (
      event.target &&
      !notificationListRef?.current?.contains(event.target) &&
      !notificationIconRef?.current?.contains(event.target) &&
      !document.getElementById("yourBoxId")?.contains(event.target)
    ) {
      setNotification(null);
    }
  };

  const handleClickNotification = (event) => {
    setNotification(event.currentTarget);
  };

  const handleUpdateUnread = async (item) => {
    try {
      if (!item.isRead) {
        const response = await updateUnReadNotification(item?._id);
        // Update the specific notification's state
        const updatedNotifications = notificationList.map((notification) =>
          notification._id === item._id
            ? { ...notification, isRead: true }
            : notification
        );

        // Update state
        setNotificationList(updatedNotifications);
        setHasUnRead(checkUnreadNotifications(updatedNotifications));

        // Open the URL
      }
      // window.open(item.url, "_blank");

      // Open the URL in the same window
      window.location.href = item.url;
      //
    } catch (error) {
      console.log(error);
    }
  };

  //
  const handleClearAllUnread = async () => {
    try {
      // Check if there are any unread notifications
      const hasUnread = notificationList.some((item) => !item.isRead);

      await clearAllUnReadNotification(); // Call API to mark all as read

      getNotifications();

      setHasUnRead(false); // Update unread state
    } catch (error) {
      console.error("Error clearing unread notifications:", error);
    }
  };

  // Function to check for unread notifications
  const checkUnreadNotifications = (notifications) => {
    return notifications?.some((notification) => !notification.isRead);
  };

  const getNotifications = async (page = 1) => {
    setNotificationListLoading(true);
    try {
      const notifications = await getUserNotifications();
      setNotificationList(notifications?.data);
      setHasUnRead(checkUnreadNotifications(notifications.data));
    } catch (error) {
      // showErrorToast(error, "error");
    } finally {
      setNotificationListLoading(false);
      setLoading(false);
    }
  };

  const handleSHowProfile = () => {
    if (currentUser.isOpen) {
      dispatch(closeProfileModal());
    } else {
      dispatch(openProfileModal());
    }
  };

  const handleLogoutButtonClick = (event) => {
    event.stopPropagation(); // Prevent click event from propagating to document body
    setShowLogout(!showLogout);
  };

  const handleLogout = () => {
    dispatch(cleanupUserSettings());
    navigation("/login");
  };

  const goBackHandler = () => {
    // Find the parent route for the current route
    const parentRoute = routes.find(
      (route) =>
        route.childrens && // Check if the route has `childrens`
        route.childrens.some((child) => location.pathname.startsWith(child))
    );

    if (parentRoute) {
      // Redirect to parent path
      navigation(parentRoute.path);
    } else {
      // Fallback to go back one step
      navigation(-1);
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenToggle = () => {
    const element = document.documentElement; // Get the root element of the document

    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleFullScreenChange = () => {
    setIsFullScreen(
      !!(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      )
    );
  };

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("msfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullScreenChange
      );
    };
  }, []);

  useEffect(() => {
    // Function to handle click events outside the logout button container
    function handleClickOutside(event) {
      if (
        logoutButtonRef.current &&
        !logoutButtonRef.current.contains(event.target)
      ) {
        setShowLogout(false);
      }
    }
    // Add event listener to the document body
    document.body.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getNotifications();
  }, []);

  /** Socket Connection */
  const [isConnected, setIsConnected] = useState(false);
  const socket = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    try {
      if (!isConnected && !socket.current) {
        socket.current = new WebSocket(`${baseURL}?token=${token}`);
        socket.current.onopen = () => {
          setIsConnected(true);
        };
      }
    } catch (error) {
      console.log(error, "Socket error");
    }
  }, []);

  useEffect(() => {
    if (isConnected && socket.current) {
      socket.current.onmessage = (ev) => {
        const event = JSON.parse(ev.data);
        console.log({ event });

        // if (event.type === "new_notification") {
        //   setHasUnRead(true);
        // }
      };
    }
  }, [isConnected]);

  return (
    <header
      style={{ zIndex: 99 }}
      className={`lg:sticky top-0 flex w-full max-h-[75px] bg-[#EDF6F9] shadow-sm`}
    >
      <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
        <div
          className="hover:cursor-pointer block md:hidden pt-2   md:w-full "
          onClick={handleMobSideBar}
        >
          {/* <img src="/icons/hamburger.svg" className="" alt="" /> */}
          <Hamburger />
        </div>
        {/* Back buttion */}
        {(pathname?.split("/").length > 2 ||
          additionalBackButtonPaths.includes(pathname)) && (
          <button
            onClick={goBackHandler}
            className="flex p-3 justify-center items-center gap-3 lg:w-[86px] lg:mx-0 mx-3 lg:h-[45px] w-[50px] h-[35px] bg-bluishgrey bg-opacity-[60%] shadow-card rounded-15 hover:scale-01"
          >
            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.337232 8.60567C0.121631 8.38689 0.000513077 8.09019 0.000513077 7.78084C0.000513077 7.47148 0.121631 7.17479 0.337232 6.956L6.84305 0.35617C6.94914 0.244741 7.07604 0.155862 7.21635 0.094718C7.35666 0.0335742 7.50757 0.00139017 7.66027 4.40491e-05C7.81297 -0.00130207 7.96441 0.0282169 8.10575 0.0868779C8.24708 0.145539 8.37549 0.232168 8.48347 0.341709C8.59145 0.451251 8.67685 0.581511 8.73467 0.72489C8.7925 0.868269 8.8216 1.0219 8.82027 1.1768C8.81894 1.33171 8.78722 1.4848 8.72694 1.62714C8.66667 1.76948 8.57906 1.89822 8.46922 2.00584L2.77648 7.78084L8.46922 13.5558C8.67871 13.7759 8.79463 14.0706 8.79201 14.3765C8.78939 14.6824 8.66844 14.975 8.45521 15.1913C8.24198 15.4076 7.95353 15.5303 7.65199 15.533C7.35046 15.5356 7.05995 15.418 6.84305 15.2055L0.337232 8.60567Z"
                fill="#DE4AC4"
              />
            </svg>

            <p className="text-bodyRB text-darkgrey hidden lg:block">Back</p>
          </button>
        )}
        {/* Back buttion end */}

        {/* Window name */}
        <h1 className="w-full flex justify-center md:ml-[15%] text-headingBB  text-darkgrey capitalize ">
          {formattedPathname ? formattedPathname : "dashboard"}
        </h1>
        {/* Window name end */}

        {/* Refresh and Logout */}
        <div className="flex items-center">
          <div ref={notificationIconRef} onClick={handleClickNotification}>
            <div className="relative group cursor-pointer">
              <div className=" hover:scale-105  lg:text-[22px] mx-3 lg:mx-0 text-[16px] text-pink lg:bg-bluishgrey bg-white lg:bg-opacity-[10%]  w-[34px] h-[36px] mt-[3px] lg:mt-0 lg:w-[43px] lg:h-[43px]  items-center justify-center  rounded-lg lg:rounded-full shadow-card mr-[2px] lg:!mr-3 flex">
                <BiSolidBell className="group-hover:animate-bell-ring origin-center" />
              </div>
              {hasUnRead && (
                <div className="w-2 h-2 rounded-full bg-danger absolute lg:right-3  right-1 lg:top-1 -top-0.5">
                  {/* Ping animation */}
                  <span className="absolute inline-flex h-full w-full rounded-full bg-danger opacity-75 animate-ping"></span>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={handleSHowProfile}
            className="hover:scale-105 w-8 h-8 lg:w-[57px] lg:h-[57px]"
          >
            <div className="hidden md:flex hover:scale-105  lg:text-[18px] text-[16px] text-pink bg-bluishgrey bg-opacity-[10%] lg:w-[43px] lg:h-[43px]  items-center justify-center  rounded-full shadow-card">
              {/* <img src="/icons/Profile.svg" /> */}
              <FaUserLarge />
            </div>
            <div className="block md:hidden -mt-1">
              <ProfileIcon_mob />
            </div>
          </button>

          <div
            onClick={handleFullScreenToggle}
            className="cursor-pointer hover:scale-105  lg:text-[24px] text-[16px] text-pink bg-bluishgrey bg-opacity-[10%]  w-[22px] h-[22px] lg:w-[43px] lg:h-[43px]  items-center justify-center  rounded-full shadow-card  mr-1 hidden md:flex"
          >
            <RiFullscreenFill />
          </div>

          <button
            className="hover:scale-105 w-8 h-8 lg:w-[57px] lg:h-[53px] hidden md:block"
            onClick={() => {
              window.location.reload();
            }}
          >
            <svg
              // width="57"
              // height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6" filter="url(#filter0_d_1183_2743)">
                <circle cx="26.5" cy="26.5" r="22.5" fill="#EDF6F9" />
              </g>
              <path
                d="M34.9288 27.6094C34.7282 29.1394 34.1148 30.586 33.1545 31.7939C32.1942 33.0019 30.9232 33.9256 29.4778 34.4661C28.0324 35.0066 26.4672 35.1435 24.9499 34.862C23.4327 34.5805 22.0207 33.8913 20.8653 32.8683C19.71 31.8453 18.8549 30.5272 18.3918 29.0552C17.9287 27.5831 17.875 26.0128 18.2366 24.5126C18.5981 23.0124 19.3612 21.639 20.444 20.5395C21.5267 19.44 22.8884 18.656 24.3829 18.2716C26.9186 17.6212 29.5089 18.1678 31.5 19.601"
                stroke="#DE4AC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M35.0003 18.0029V23.3157H29.6875" fill="#E6F2F7" />
              <path
                d="M35.0003 18.0029V23.3157H29.6875"
                stroke="#DE4AC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <filter
                  id="filter0_d_1183_2743"
                  x="0"
                  y="0"
                  width="57"
                  height="57"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="3" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1183_2743"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1183_2743"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </button>

          <button
            className="hover:scale-105 w-8 h-8 lg:w-[57px] lg:h-[53px] hidden md:block"
            // onClick={handleLogout}
            onClick={handleLogoutButtonClick}
          >
            <LogoutIcon />
          </button>
          {showLogout && (
            <div
              ref={logoutButtonRef} // This ref is used to determine if a click is inside or outside the container
              className="
             bg-[#E9EFFB] flex min-w-[100px] min-h-[35px] sm:min-w-[110px]  justify-between
             items-center text-[#100D2C] -mx-10 lg:mx-[160px] shadow-card rounded-md p-3 absolute top-full  
             pl-5 pr-5 font-sans text-sm font-semibold hover:cursor-pointer "
            >
              <div className="">
                <div className="flex justify-center h-10 cursor-default max-w-[150px] overflow-hidden">
                  <div className="overflow-hidden overflow-ellipsis">
                    {currentUser.displayName}
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleLogout();
                  }}
                  className="flex items-center justify-between hover:scale-105"
                >
                  <FiLogOut className="font-bold" />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Refresh and Logout end*/}

        <ProfileModal />

        <PopupImages />
      </div>

      {notificationOpen && (
        <div
          ref={notificationListRef}
          className={`animate-in rounded-lg w-[350px] `}
          style={{
            position: "absolute",
            top: 80,
            right: 10,
            backgroundColor: "white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 1000, // Adjust as needed
          }}
        >
          <div className=" w-[350px] min-h-[500px] max-h-[500px] overflow-y-auto custom-scrollbar">
            {notificationListLoading ? (
              <>
                {[...Array(10)].map((_, index) => (
                  <div></div>
                ))}
              </>
            ) : (
              <div className="bg-white border min-h-[500px] rounded-lg relative">
                <div className="min-h-[420px]">
                  <div className="flex justify-between items-center pl-5 rounded-tl-lg rounded-tr-lg sticky top-0 bg-white z-10">
                    <div className="text-sm font-sora text-center w-full">
                      Notifications
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setNotification(null);
                      }}
                    >
                      <Close />{" "}
                    </button>
                  </div>
                  {notificationList.length === 0 && (
                    <div className="h-[350px] flex flex-col gap-5 justify-center items-center">
                      <img
                        src={"/dentist-day.png"}
                        alt="icon"
                        className="w-[100px] h-[100px]"
                      />
                      <div className=" font-gilroysemibold">
                        No Notifications Yet
                      </div>
                    </div>
                  )}
                  {notificationList?.map((notification, index) => (
                    <div
                      key={index}
                      className={`min-h-14 cursor-pointer  border-b border-gray-400 hover:shadow-inner ${
                        notification?.isRead ? "opacity-60" : "bg-gray-100"
                      }`}
                      onClick={() => handleUpdateUnread(notification)}
                    >
                      <div className="pl-5 pr-5 pt-1">
                        <div className="font-gilroyregular w-full break-words text-[13.5px] text-[#263238] h-fit pt-1">
                          {notification?.description}
                        </div>
                        <div className="w-full min-h-7 py-2 pb-1">
                          <p className="font-gilroyregular text-[11px] text-primary-main min-w-max">
                            {dayjs(notification?.createdAt).format(
                              "DD MMM YYYY, hh:mm a"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="bg-white sticky bottom-0 flex justify-end mt-6 p-2  text-sm rounded-bl-lg rounded-br-lg z-10">
                  <button
                    onClick={handleClearAllUnread}
                    className="p-1 border bg-gray-300 rounded-md "
                  >
                    Clear all
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
