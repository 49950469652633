import React, { useEffect, useState } from "react";
import StockInComponent from "./StockInComponent";
import BatchDetailsComponent from "./BatchDetailsComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addStockIn,
  getBatchId,
  getSerialNumberStockIn,
} from "../../../../api/Inventory/stockin/stockin";
import { showErrorToast, showSuccessToast } from "../../../../utils/toaster";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const purchase_details = {
  purchase_date: new Date(),
  invoice_number: "",
  batch: "",
  supplier: "",
  quantity: "",
  uom: "",
  expiry_date: "",
  // expiry_date:dayjs(new Date()).format("YYYY-MM-DD"),
  purchase_price: "",
  notes: "",
};

const validationSchema = Yup.object().shape({
  purchase_details: Yup.array().of(
    Yup.object().shape({
      purchase_date: Yup.string().required("date is required "),
      invoice_number: Yup.string(),
      batch: Yup.string().required("date is required "),
      supplier: Yup.string(),
      quantity: Yup.number().required("date is required "),
      expiry_date: Yup.string(),
      purchase_price: Yup.number(),
      notes: Yup.string(),
    })
  ),
  // date: Yup.string().required(),
  code: Yup.string().required("code is required"),
  name: Yup.string().required("name is required"),
  catagory: Yup.string().required("catagory is required"),
  brand: Yup.string(),

  uom: Yup.string().required("uom is required"),
  quantity: Yup.number().required("quantity is required"),
  selling_price: Yup.number().required("selling_price is required"),
});

const StockInLayout = ({
  stockInData,
  suppliers,
  searchSupplier,
  setSearchSupplier,
  editData,
}) => {
  const [loading, setLoading] = useState(false);
  const [stockInSerial, setStockInSerial] = useState();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    _id: stockInData?._id,
    code: stockInData?.code || "",
    name: stockInData?.name || "",
    catagory: stockInData?.catagory || "",
    sub_category: stockInData?.sub_category || "",
    brand: stockInData?.brand || "",
    modal: stockInData?.modal || "",
    uom: stockInData?.uom || "",
    quantity: stockInData?.quantity || 0,
    selling_price: stockInData?.selling_price || 0,
    purchase_details: [],
  });

  useEffect(() => {
    const setInitialValuesWithData = async () => {
      const purchaseDetails = {
        purchase_date: new Date(),
        invoice_number: "",
        batch: "",
        supplier: "",
        quantity: "",
        uom: stockInData?.uom || "",
        expiry_date: "", // dayjs(new Date()).format("YYYY-MM-DD"),
        purchase_price: "",
        notes: "",
      };

      try {
        const response = await getBatchId(1);
        purchaseDetails.batch = response?.data?.batch_number || "";
      } catch (error) {
        console.error("Error fetching batch number:", error);
      }

      setInitialValues({
        _id: stockInData?._id || "",
        code: stockInData?.code || "",
        name: stockInData?.name || "",
        generic_name: stockInData?.generic_name || "",
        catagory: stockInData?.catagory || "",
        sub_category: stockInData?.sub_category || "",
        brand: stockInData?.brand || "",
        modal: stockInData?.modal || "",
        uom: stockInData?.uom || "",
        quantity: stockInData?.quantity || 0,
        selling_price: stockInData?.selling_price || 0,
        purchase_details: [purchaseDetails],
      });
    };

    setInitialValuesWithData();
  }, [stockInData]);

  useEffect(() => {
    getSerialNumberStockIn()
      .then((response) => {
        const serialNumber = parseInt(response.data.data.SI_NO);
        setStockInSerial(serialNumber);

        // formik.setFieldValue(`code`, serialNumber);
      })
      .catch((error) => {
        console.log(error);
        showErrorToast(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        item_id: values._id,
        purchase_details: values.purchase_details.map((detail) => ({
          ...detail,
          expiry_date:
            detail.expiry_date &&
            dayjs(detail.expiry_date).format("YYYY-MM-DD"),
          purchase_date:
            detail.purchase_date &&
            dayjs(detail.purchase_date).format("YYYY-MM-DD"),
        })),
      };
      setLoading(true);
      addStockIn(payload)
        .then((response) => {
          showSuccessToast("Stock In Updated");
          navigate("/inventory");
        })
        .catch((error) => {
          showErrorToast(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const addRow = async (index) => {
    try {
      const response = await getBatchId(index);
      formik.setValues({
        ...formik.values,
        purchase_details: [
          ...formik.values.purchase_details,
          {
            ...purchase_details,
            batch: response?.data?.batch_number,
            uom: stockInData?.uom,
          },
        ],
      });
    } catch (error) {
      // console.log(error);
    }
  };

  /** Removing row using index */
  const removeRow = (index) => {
    const updatedItems = [...formik.values.purchase_details];
    if (updatedItems.length !== 1) {
      updatedItems.splice(index, 1);
      formik.setValues({
        ...formik.values,
        purchase_details: updatedItems,
      });
    }
  };

  return (
    <div className="flex flex-col">
      <StockInComponent
        stockInData={stockInData}
        formik={formik}
        stockInSerial={stockInSerial}
      />
      <BatchDetailsComponent
        formik={formik}
        addRow={addRow}
        removeRow={removeRow}
        suppliers={suppliers}
        setSearchSupplier={setSearchSupplier}
        searchSupplier={searchSupplier}
      />
      {/* <div className="grid grid-cols-6">
            <div className="col-start-6 col-end-7 my-6 ">
              <Button
                // loading={loading}
                type={"primary"}
                className={" text-heading2B"}
              >
                Save
              </Button>
            </div>
          </div> */}
    </div>
  );
};

export default StockInLayout;
