import { api } from "../../../config/axios/interceptor";

// export async function getAllInventoryItems(searchKey,catagory,stock="asc",price="asc",page,pageSize) {
export async function getAllInventoryItems(
  searchKey,
  page,
  pageSize,
  catagory,
  key,
  order,
  selectedSubCategory
) {
  try {
    const response = await api.get(
      `inv_item_master/dashboard?search=${searchKey}&page=${page}&pageSize=${pageSize}&catagory=${catagory}${
        selectedSubCategory && `&sub_category=${selectedSubCategory}`
      }&${key}=${order}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getAllInventoryItemsForOtherThanDashboard(searchKey) {
  try {
    const response = await api.get(`inv_item_master?search=${searchKey}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getEachInventoryItems(id) {
  try {
    const response = await api.get(`inv_item_master/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSerialNumberAddInventory() {
  try {
    const response = await api.get(`inv_item_master/getserialno`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateItem(values, id) {
  try {
    const response = await api.put(`inv_item_master/${id}`, values);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function addItem(values) {
  try {
    const response = await api.post(`inv_item_master`, values);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getUOMList() {
  try {
    const response = await api.get(`inv_item_master/getuom`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteItem(id) {
  try {
    const response = await api.delete(`inv_item_master/` + id);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSubCategory({ category }) {
  try {
    const response = await api.get(
      `inv_sub_category_master/?category=${category}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function addSubCategory({ category, subCategoryList }) {
  const payload = {
    category: category,
    sub_category: subCategoryList,
  };
  try {
    const response = await api.put(`inv_sub_category_master`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}
